export const enhancer = el => {
  const unload = el;
  /* eslint-disable */
  window.addEventListener('beforeunload', onbeforeunload);
    function onbeforeunload(e) {
      unload.classList.add('unload--loading');

    };
  /* eslint-enable */
};
