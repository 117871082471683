/* eslint-disable */
const setTheme = () => {
  if (localStorage.getItem('theme')) {
    document.documentElement.setAttribute('data-theme', localStorage.getItem('theme'));
    gtag('event', 'theme-picker', {
      'event_category' : 'theme',
      'event_label' : localStorage.getItem('theme')
    });
  }
}

export const enhancer = el => {
  const path = el.querySelectorAll('path');
  for (let i = 0; i < path.length; i += 1) {
    path[i].addEventListener('click', e => {
      localStorage.setItem('theme', path[i].getAttribute('data-theme'));
      setTheme();
    });
  }
};
