import { isVisible } from './scroll-listener';
import { map } from './util';

/* eslint-disable */



let index;
let isSeen = false;
let i = 0;

const show = el => {
  const items = getItems(el);
  setTimeout(() => {
    if (i < items.length) {
      items[i].removeAttribute('style');
      /* eslint-disable no-plusplus */
      i++;
      show(el);
    }
  }, 100);


  map(items, (item) => {
    // item.removeAttribute('style');
  });
}

const checkElementView = el => {
  if (!isSeen && isVisible(el)) {
    setTimeout(() => {
      show(el);
    }, 300);
    isSeen = true;
  }
};

export const getItems = el => {
  return el.getElementsByClassName('location__list-item');
}

export const enhancer = el => {
  const items = getItems(el);
  map(items, (item) => {
    item.style.opacity = 0.1;
  });

  checkElementView(el);
  window.addEventListener('scroll', e => {
    checkElementView(el);
  });


/*


// Get User's Coordinate from their Browser
// window.onload = function() {
  // HTML5/W3C Geolocation
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(UserLocation);
  }
  // Default to Washington, DC
  else
    NearestCity(38.8951, -77.0367);
// }
*/

};


// Callback function for asynchronous call to HTML5 geolocation
function UserLocation(position) {
  NearestCity(position.coords.latitude, position.coords.longitude);
}


// Convert Degress to Radians
function Deg2Rad(deg) {
  return deg * Math.PI / 180;
}

function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lon1 = Deg2Rad(lon1);
  lon2 = Deg2Rad(lon2);
  var R = 6371; // km
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = (lat2 - lat1);
  var d = Math.sqrt(x * x + y * y) * R;
  return d;
}

function NearestCity(latitude, longitude) {
  var mindif = 99999;
  var closest;
var items = [];
  for (index = 0; index < viisi.locations.length; ++index) {
    var dif = PythagorasEquirectangular(latitude, longitude, viisi.locations[index]['lat'], viisi.locations[index]['long']);
// console.log(dif);
    if (dif < mindif) {
      closest = index;
      mindif = dif;
      items.push({dif, index});
    }
  }
  // echo the nearest city
  // console.log( viisi.locations[closest]);
}

/* eslint-enable */
