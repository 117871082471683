/**
 * Set Time Out
 */
const SHOW_FOCUS_TIMEOUT = 30;

/**
 * Focus search form
 */
export const enhancer = el => {
  el.addEventListener('click', e => {
    if (el.hash === '#zoeken') {
      setTimeout(() => {
        document.getElementsByClassName('searchform__input')[0].focus();
      }, SHOW_FOCUS_TIMEOUT);
    }
  });
};
