/* eslint-disable */
/**
 * @todo
 * Nieuw veld: Gewenste lening
 */

export const getData = () => {
  const mailButton = document.getElementById('calc__mail_results');
  if (typeof(mailButton) == 'undefined' || mailButton == null) {
    return;
  }

  if (localStorage) {
    const fields = [
      'calc__field-gross-income',
      'calc__field-partner-income',
      'calc__field-rate',
      'calc__field-purchase-price',
      'calc__field-studentloan-short',
      'calc__field-studentloan-long',
      'calc__field-debts',
    ];
    fields.forEach((element) => {
      if (document.getElementById(element)) {
        document.getElementById(element).addEventListener("keyup", e => {
          let name = element.replace('calc__field-', 'max_hyp_').replace(/-/g, '_');
          let value = document.getElementById(element).value;
          localStorage.setItem(name, value);
          if ( document.getElementById('calc__field-rate').value && document.getElementById('calc__field-purchase-price').value) {
            // tmp @todo
            if (viisi.theme == 'viisi') {
              document.getElementById('calc__mail_results').classList.remove('calc__mail_results--disabled');
            }
          }
        });
      }
    });

    const output = [
      'calc__max-mortgage-amount__val',
      'calc__max-monthly-payment__val',
      'calc__max-monthly-payment-1st-month-principal__val',
      'calc__max-monthly-payment-1st-month-interest__val',
      'calc__max-monthly-payment-5th-year-principal__val',
      'calc__max-monthly-payment-5th-year-interest__val',
      'calc__own-capital__val',
      'calc__own-capital-extra-capital__val',
      'calc__own-capital-taxes__val',
      'calc__own-capital-realtor-fee__val',
    ];
    const results = document.getElementById('calc__mail_results');
    results.addEventListener("click", e => {
      output.forEach((element) => {
        let item = document.getElementById(element).innerHTML;
        let name = element.replace('calc__', 'max_hyp_').replace('__val', '').replace(/-/g, '_');
        localStorage.setItem(name, item);
      });
    });
  }
};

export const enhancer = dateInput => {

  if (document.getElementById('calc__field-appraisal-costs')) {
    let buildingTypes = document.querySelectorAll('[name="calc__inputs__existingbuilding-newbuilding"]');
    buildingTypes.forEach((buildingType) => {
        buildingType.addEventListener("click", e => {
          let newConstruction = getElem('calc__radio-newbuilding') ? getElem('calc__radio-newbuilding').checked : 0;
          if (newConstruction) {
            let appraisal_costs = '';
            let notary_fees = 750;
            document.getElementById('calc__field-appraisal-costs').value = appraisal_costs;
            document.getElementById('calc__field-notary-fees').value = notary_fees;
          } else if (!document.getElementById('calc__field-appraisal-costs').value) {
            let appraisal_costs = 500;
            let notary_fees = 1500;
            document.getElementById('calc__field-appraisal-costs').value = appraisal_costs;
            document.getElementById('calc__field-notary-fees').value = notary_fees;
          }
        });
    });
  }
  getData();
  // Polyfil for IE11 (for some reason the framework isn't taking care of this)
  if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i += 1) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  let [bestMortage] = [];

  const getElem = (elementId) => {
    return document.getElementById(elementId);
  };
  const calcMonthlyInsurance = (loanAmount, age, months) => {
    return 0; // TODO: putting in a constant until we have a calculation for it
  };

  // This function supports having any portion of the loan be annuity and linear,
  // even though the UI requires that it's 100% of one or the other.
  const calcMonthlyPayment = (loanAmount, rate, months, percentLinear, age) => {
    if (!percentLinear) {
      percentLinear = 0;
    }
    const linearAmount = loanAmount * percentLinear;
    const annuityAmount = loanAmount - linearAmount;

    // Assumes monthly payments, though it's possible to
    // structure a mortage to have 13 payments a year.
    const annuityMonthlyPayment =
      (annuityAmount * (rate / 12)) / (1 - ((1 + (rate / 12)) ** (-months)));
    const linearMonthlyPayment =
      (linearAmount * (rate / 12)) + (linearAmount / months);
    //console.log(`linearAmount: ${linearAmount}, annuityAmount: ${annuityAmount}, annuityMonthlyPayment: ${annuityMonthlyPayment}, linearMonthlyPayment: ${linearMonthlyPayment}`);
    const monthlyInsurancePayment = calcMonthlyInsurance(loanAmount, age, months);

    // We round the final result so it looks nice when we output it in the UI
    const monthlyPayment =
      Math.round(annuityMonthlyPayment + linearMonthlyPayment + monthlyInsurancePayment);

      // console.log('monthlyPayment', monthlyPayment);

    // It's possible that not enough of the inputs have been provided or they were invalid and
    // we calculated a negative result. In that case, just return 0 to avoid confusing people.
    return monthlyPayment > 0 ? monthlyPayment : 0;
  };

  // Iterate over all the loan portions and calculate the monthly payment for each of them.
  // We only consider their impact to the first month even though they will get paid off
  // faster than the overall loan.
  const calculateLoanPartsPortions = (loanParts, rate) => {
    let totalAmount = 0;
    let totalMonthlyPayments = 0;
    loanParts.map((loanPart) => {
      if (loanPart.amount && loanPart.monthsRemaining) {
        totalAmount += loanPart.amount;
        totalMonthlyPayments += calcMonthlyPayment(loanPart.amount, rate, loanPart.monthsRemaining);
      }
      // if loan part is higher then best mortgage. Reset amounts and keep current mortgage.
      if (bestMortage < loanPart.amount) {
        totalAmount = 0;
        totalMonthlyPayments = 0;
      }
    });
console.log('totalMonthlyPayments', totalMonthlyPayments);
console.log('totalAmount', totalAmount);

    return [totalAmount, totalMonthlyPayments];
  };


  const incomeToTableKey = (testIncome) => {
    let roundedIncomeForPercentage;
    if (testIncome < 20500) {
      // Use 0 as a placeholder for any income bellow 20500
      roundedIncomeForPercentage = 0;
    } else if (testIncome > 110000) {
      // 110000 is as high as the table goes, so max income out at this number
      roundedIncomeForPercentage = 110000;
    } else if (testIncome < 25000) {
      // For incomes under 25000, the table increments go by 500, so round DOWN to the nearest 500.
      roundedIncomeForPercentage = Math.floor(testIncome / 500) * 500;
    } else {
      // For at and above 25000, the table increments go by 1000, so round DOWN to the nearest 1000.
      roundedIncomeForPercentage = Math.floor(testIncome / 1000) * 1000;
    }
    return roundedIncomeForPercentage;
  };

  const rateToTableKey = (rate) => {
    // console.log(`rate: ${rate}`);
    if (rate <= 0.01) {
      return '<=1000';
    } else if (rate >= 0.06001) {
      return '>6000';
    }

    // The table has interest ranges for every half percent, so round UP to the nearest half.
    const roundedRate = Math.ceil(rate / 0.005) / 2;
    return roundedRate * 1000;
  };

  // We have a spreadsheet from the Dutch regulator that lists how much
  // someone's income they can spend monthly on their mortgage payments.
  // The rows are income grouped by 5k or 10k increments, and the columns
  // are interest rates. The cells are the percentage of the borrower's
  // income that they may spend on their mortgage each month.
  //
  // We flatten the table into a hash so that every row/column combination
  // becomes the key and the percent value at that row/column is value.
  // This function computes the key and looks up the percent value.
  const lookupMaxPaymentPercent = (testIncome, rate, incomeToMaxPaymentPercentTable) => {
    const incomeKey = incomeToTableKey(testIncome);
    const rateKey = rateToTableKey(rate);
    const tableKey = `${incomeKey}-${rateKey}`;
    const monthlyPaymentPercent = incomeToMaxPaymentPercentTable[tableKey];
    // console.log(`looked up monthlyPaymentPercent: ${monthlyPaymentPercent}`);
    return monthlyPaymentPercent;
  };

  // This is a standard formula for calculating the total mortgage amount given an annual
  // interest rate, number of monthly payments, and the amount of the monthly payments.
  const calculateAnuityMortgage = (rate, months, maxMonthlyPayment) => {
    const monthlyInterest = (rate / 12); // Assumes monthly payments and not 13 payment per year.
    const pow = (monthlyInterest + 1) ** months;
    const annuityFactor = (monthlyInterest * (pow)) / ((pow) - 1);
    return maxMonthlyPayment / annuityFactor;
  };

  const calcMaxMonthlyPayment =
    (calcInputs, rate, incomeToPercentTable, loanPartsMonthlyPayment) => {

// console.log('Maarten 1');
// console.log('calcInputs', calcInputs);
// console.log('rate', rate);
// console.log('incomeToPercentTable', incomeToPercentTable);
// console.log('loanPartsMonthlyPayment', loanPartsMonthlyPayment);
// console.log('Maarten 2');

      // Deduct alimony from the gross income
      const grossIncome = calcInputs.grossIncome - (calcInputs.alimony * 12);

      // 100%
      let testIncome = grossIncome + calcInputs.partnerIncome;
      if( getIncomeTablesKey() == 2022 ) {
        // Only 90% of the partner income counts towards the test income
        testIncome = grossIncome + (calcInputs.partnerIncome * 0.9);
      }

      let maxPaymentPercent = lookupMaxPaymentPercent(testIncome, rate, incomeToPercentTable);

      // For single incomes between 20k and 31k, an additional 3& of income may
      // be used up to a maximum of what someone would get with 31k of income.
      //
      // NOTE: We're using 20.500 as the minimum income because that's what
      // Adviesbox does. The actual rule says the minimum is 20.000.
      if (!calcInputs.partnerIncome && grossIncome <= 31000 && grossIncome > 20500) {
        maxPaymentPercent += 3;
        const maxPaymentPercent31k = lookupMaxPaymentPercent(31000, rate, incomeToPercentTable);
        maxPaymentPercent = Math.min(maxPaymentPercent, maxPaymentPercent31k);
      }

      // We're simply multiplying the annual income by the "maximum payment as a percent of income"
      // allowed and then converting it to monthly by dividing by 12
      const totalIncome = (grossIncome + calcInputs.partnerIncome);
      let maxMonthlyPayment = ((totalIncome * (maxPaymentPercent / 100)) / 12);

      // Debt rule says that the maximum allowed monthly payment is reduced by 2% of the total debt
      maxMonthlyPayment -= calcInputs.debts * 0.02;

      // Student loans are like debts, but have a different
      // let studentLoanRate = '';
      // multiplier depending on if they are 15 or 35 year loans.
      // if( getIncomeTablesKey() == 2020 ) {

      if (calcInputs.studentLoanShort) {
        maxMonthlyPayment -= calcInputs.studentLoanShort * 0.0075;
      }

      if (calcInputs.studentLoanLong) {
        maxMonthlyPayment -= calcInputs.studentLoanLong * 0.0035;
      }
console.log('maxMonthlyPayment 2: ', maxMonthlyPayment);
      // We're already paying something monthly for the loan parts,
      // so the monthly payment needs to be reduced by that amount.
      maxMonthlyPayment -= loanPartsMonthlyPayment;
console.log('loanPartsMonthlyPayment 2: ', loanPartsMonthlyPayment);
console.log('maxMonthlyPayment 3: ', maxMonthlyPayment);
      // Goundlease is simply substracted from allowed monthly payments euro for euro.
      maxMonthlyPayment -= calcInputs.annualGroundLease / 12;

      return maxMonthlyPayment;
    };

  const calcMaxMortgageWithTable =
    (calcInputs, rate, incomeToPercentTable, loanPartsMonthlyPayment) => {
      const maxMonthlyPayment =
        calcMaxMonthlyPayment(calcInputs, rate, incomeToPercentTable, loanPartsMonthlyPayment);
      const maxMortgage = calculateAnuityMortgage(rate, calcInputs.months, maxMonthlyPayment);
      // Check to make sure we have a valid result to avoid
      // confusing users (in case of incomplete or invalid inputs)
      return maxMortgage < 0 ? [0, 0] : [maxMortgage, maxMonthlyPayment];
    };

  const calcMaxMortgage = (inputs, rate, paymentAmountLoanParts, mortageAmountLoanParts) => {
    // Don't calculate with negligible incomes to
    // avoid displaying numbers that don't make sense
    if ((inputs.grossIncome + inputs.partnerIncome) < 5000 || rate === 0) {
      return [0, 0];
    }

    // Calculate regular and box3/non-deductible portions of the
    // mortgage separately as they use different income tables.
    const [maxMortgageStandard, maxMonthlyPaymentStandard] =
      calcMaxMortgageWithTable(inputs, rate, inputs.incomeTables.standard, paymentAmountLoanParts);
    const [maxMortgageBox3, maxMonthlyPaymentBox3] = !inputs.box3amount ? [0, 0] :
      calcMaxMortgageWithTable(inputs, rate, inputs.incomeTables.box3, paymentAmountLoanParts);

    // Use the box3/box3amount divided by the total non-deductible
    // portions of the loan (box3 + loan parts) as the proportion of the box3 loan.
    // NOTE: This ratio doesn't quite make sense to me, but this is what brings the
    // results closest to Adviesbox
    const box3percentage =
      !inputs.box3amount ? 0 : (inputs.box3amount) / (maxMortgageBox3 + mortageAmountLoanParts);
    // console.log(`maxMortgageStandard: ${maxMortgageStandard}, maxMortgageBox3: ${maxMortgageBox3}, box3percentage: ${box3percentage}, loanPartsMonthlyPayment: ${paymentAmountLoanParts}, loanPartsTotalAmount: ${mortageAmountLoanParts}`);

    // Multiply the the above ratio to figure out what total amount of the loan is standard vs box3.
    const mortgageAmountStandard = maxMortgageStandard * (1 - box3percentage);
    const mortgageAmountBox3 = maxMortgageBox3 * box3percentage;

    // Do the same as above to calculate the monthly payment amounts
    const paymentAmountStandard = maxMonthlyPaymentStandard * (1 - box3percentage);
    const paymentAmountBox3 = maxMonthlyPaymentBox3 * box3percentage;

    // Simply add up the total and monthly amounts to get to the answer
    let maximumMortgage = mortgageAmountStandard + mortgageAmountBox3 + mortageAmountLoanParts;
    let maximumMonthlyPayment = paymentAmountStandard + paymentAmountBox3 + paymentAmountLoanParts;

    // If there's a very large box3 amount, then calculation returns a negative
    // number. Keeping it at 0 in that case so the numbers make sense.
    if (maximumMortgage < 0) {
      maximumMortgage = 0;
      maximumMonthlyPayment = 0;
    }

    // console.log(`mortPart1: ${mortgageAmountStandard}, mortPart2: ${mortgageAmountBox3}, maxMort: ${maximumMortgage}, maxMonthly: ${maximumMonthlyPayment}`);

    // (Conservatively) round the results down to the nearest 1.000
    return [
      Math.floor((maximumMortgage) / 1000.0) * 1000,
      Math.ceil(maximumMonthlyPayment),
    ];
  };

  const spinnerTimeouts = [];
  const setOutputValue = (outputValueId, outputValue, outputValue2) => {
    outputValueId = `calc__${outputValueId}`;
    const outputParent = getElem(outputValueId);

    // Don't do anything if this element isn't present, since
    // different calculator templates may have different outputs.
    if (!outputParent) {
      return;
    }

    const outputElement = getElem(`${outputValueId}__val`);
    let newOutputText = outputValue.toLocaleString();
    if (outputValue2) {
      newOutputText += outputValue2;
    }

    // Don't do anything if the output value hasn't changed.
    if (outputElement.innerText === newOutputText) {
      return;
    }

    const loader = getElem(`${outputValueId}__loader`);

    // Hide the output values and show loaders instead
    loader.style.visibility = 'visible';
    outputParent.classList.add('calc__output-display-val--hidden');

    const hideSpinnerAndShowOutputValue = () => {
      outputElement.innerHTML = newOutputText;

      loader.style.visibility = 'hidden';

      outputParent.classList.remove('calc__output-display-val--hidden');
      calcPreapprovalResultFields();
      const breakdown = getElem(`${outputValueId}-breakdown`);
      if (breakdown && !breakdown.classList.contains('calc__output-breakdown--visible')) {
        breakdown.classList.add('calc__output-breakdown--visible');
      }
    };

    const calcPreapprovalResultFields = () => {
      if (!document.getElementById('calc-preapproval__result-fields')) {
        return;
      }
      const wrapper = document.getElementById('calc-preapproval__result-fields');
      const languageSwitcher = document.querySelectorAll('.calc__field-language-NL-language-EN input');
      for(let i=0;i<languageSwitcher.length;i++) {
        languageSwitcher[i].addEventListener('click', function(){
          document.querySelector('#selected_language').setAttribute('value', languageSwitcher[i].value);
          document.querySelector('#pdf_name').setAttribute('value', languageSwitcher[i].value == 'NL' ? 'Hypotheekberekening' : 'Mortgage calculator');
        });
      }
      wrapper.innerHTML = '';
      const span = document.getElementsByTagName('span');
      for(let i=0;i<span.length;i++) {
        let value = span[i].textContent;
        let id = span[i].getAttribute('id')
        if (id && (id.indexOf("__val") > 0)) {
          let input = document.createElement("input");
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', id);
          input.setAttribute('value', value);
          wrapper.appendChild(input);
        }
      }
      let inputLanguage = document.createElement("input");
      inputLanguage.setAttribute('type', 'hidden');
      inputLanguage.setAttribute('id', 'selected_language');
      inputLanguage.setAttribute('name', 'calc__language');
      inputLanguage.setAttribute('value', getElem('calc__radio-language-NL').checked ? 'NL' : 'EN');
      wrapper.appendChild(inputLanguage);


      let inputStudenLoanShort = document.createElement("input");
      inputStudenLoanShort.setAttribute('type', 'hidden');
      inputStudenLoanShort.setAttribute('id', 'studentloan-short');
      inputStudenLoanShort.setAttribute('name', 'calc__studentloan-short');
      inputStudenLoanShort.setAttribute('value', getElem('calc__field-studentloan-short').value);
      wrapper.appendChild(inputStudenLoanShort);

      let inputStudenLoanLong = document.createElement("input");
      inputStudenLoanLong.setAttribute('type', 'hidden');
      inputStudenLoanLong.setAttribute('id', 'studentloan-long');
      inputStudenLoanLong.setAttribute('name', 'calc__studentloan-long');
      inputStudenLoanLong.setAttribute('value', getElem('calc__field-studentloan-long').value);
      wrapper.appendChild(inputStudenLoanLong);

      let inputPdf = document.createElement("input");
      inputPdf.setAttribute('type', 'hidden');
      inputPdf.setAttribute('id', 'pdf_name');
      inputPdf.setAttribute('name', 'pdf_name');
      inputPdf.setAttribute('value', getElem('calc__radio-language-NL').checked ? 'Hypotheekberekening' : 'Mortgage calculator');
      wrapper.appendChild(inputPdf);

      let inputRequiredMortgage = document.createElement("input");
      inputRequiredMortgage.setAttribute('type', 'hidden');
      inputRequiredMortgage.setAttribute('id', 'mortgage');
      inputRequiredMortgage.setAttribute('name', 'calc__mortgage');
      inputRequiredMortgage.setAttribute('value', getElem('calc__field-mortgage').value);
      wrapper.appendChild(inputRequiredMortgage);
    }

    // Wait one second before showing the output values and hiding the spinner loaders.
    const spinnerTimeout = setTimeout(hideSpinnerAndShowOutputValue, 1000);

    // If there's already a timeout going for this output value, clear it so it doesn't prematurely
    // hide the loader. We always want to wait 1 second from the last keyboard stroke.
    const oldTimeout = spinnerTimeouts[outputValueId];
    if (oldTimeout) {
      clearTimeout(oldTimeout);
    }

    // Save the current timeout so we could cancel it later if we need to.
    spinnerTimeouts[outputValueId] = spinnerTimeout;
  };


  const setOutputWithRate = (outputValueId, outputValue, outputRate) => {
    if (outputValue > 0) {
      setOutputValue(outputValueId, outputValue, ` @ ${(outputRate * 100).toLocaleString()}%`);
    } else {
      setOutputValue(outputValueId, outputValue);
    }
  };

  const getIntInput = (fieldId) => {
    const element = getElem(`calc__field-${fieldId}`);

    // If this element wasn't rendered, ignore it.
    if (!element) {
      return 0;
    }

    // Clear all non-numeric text and then parse the number.
    const fieldVal = parseInt(element.value.replace(/\D/g, ''), 10);
    return fieldVal > 0 ? fieldVal : 0;
  };

  const toggleAdvancedDisplay = () => {
    document.querySelectorAll('.calc__advanced-option').forEach((element) => {
      element.classList.toggle('calc__advanced-option--visible');
    });
  };

  function calcOwnCapitalRequired(purchasePrice, mortgage, loanAmount, hasTransferTax, nhgFee) {
    const nullResults = {
      total: 0,
      extraCapital: 0,
      taxes: 0,
      realtorFee: 0,
      appraisalFee: 0,
      adviceFee: 0,
      notaryFee: 0,
      renovation: 0,
      nhgFee: 0
    };

    // Don't calculate the OwnCapitalRequired if we haven't
    // calculated a mortgage yet or no purchase price is specified.
    if (purchasePrice === 0 || mortgage === 0) {
      return nullResults;
    }

    let extraCapital = 0;

    // If the purchase price is higher than the maxMortgage, add it to the purchase price.
    if (purchasePrice >= 0 && mortgage > 0 && purchasePrice > mortgage) {
      extraCapital = Math.round(purchasePrice - mortgage);
    }

    // These are all hard-coded ratios and fixed amounts to add to the required
    // capital for closing the loan. These are a rough approximation and will not
    // be precisely correct for the final mortgage.
    let transferTax = hasTransferTax / 100;
    const taxes = !hasTransferTax ? 0 : (purchasePrice) ? Math.round(purchasePrice * transferTax) : Math.round(loanAmount * transferTax);

    const realtorFee = (document.getElementById('calc__field-brokerage-fees') != null) ? Math.round(document.getElementById('calc__field-brokerage-fees').value) : Math.round(loanAmount * 0.015);
    const appraisalFee = (document.getElementById('calc__field-appraisal-costs') != null) ? Math.round(document.getElementById('calc__field-appraisal-costs').value) : 654;
    const adviceFee = (document.getElementById('calc__field-consultancy-costs') != null) ? Math.round(document.getElementById('calc__field-consultancy-costs').value) : 2500;
    const notaryFee = (document.getElementById('calc__field-notary-fees') != null) ? Math.round(document.getElementById('calc__field-notary-fees').value) : 2500;
    const renovation = (document.getElementById('calc__field-renovation') != null) ? Math.round(document.getElementById('calc__field-renovation').value) : 0;

    const ownCapitalRequired =
      extraCapital + taxes + realtorFee + appraisalFee + adviceFee + notaryFee + renovation + nhgFee;

    return {
      "total": ownCapitalRequired,
      "extraCapital": extraCapital,
      "taxes": taxes,
      "realtorFee": realtorFee,
      "appraisalFee": appraisalFee,
      "adviceFee": adviceFee,
      "notaryFee": notaryFee,
      "renovation": renovation,
      "nhgFee": nhgFee,
    };
  }

  function getRate(rateId) {
    const rateField = getElem(`calc__field-${rateId}`);
    if (!rateField) {
      return 0;
    }
    let rate = parseFloat(rateField.value);

    // If the rate input is a double digit, we assume that a decimal wasn't included (possibly
    // because it's missing on a mobile keyboard), so we'll add one in for the user.
    while (rate >= 10) {
      rate /= 10;
      rateField.value = rate;
    }

    // Turn the integer percentage (0-100) into a decimal percent (0.0-1.0)
    rate /= 100;

    // Ensure a sensible output even on bad inputs
    rate = rate > 0 ? rate : 0;

    return rate;
  }

  const findAncestor = (element, cls) => {
    element = element.parentElement;
    while (element && !element.classList.contains(cls)) {
      element = element.parentElement;
    }
    return element;
  };

  const renumberLoanParts = () => {
    let i = 1;
    let iva = 1;
    let ivs = 1;
    document.querySelectorAll('.calc-preapproval__loan-part--added .calc-preapproval__loan-part-number-val').forEach((loanPartNum) => {
      loanPartNum.innerHTML = `${i}e`;
      i += 1;
    });
    document.querySelectorAll('.calc-preapproval__loan-part--added .calc__field-mortgage-part-amount').forEach((input) => {
      let name = input.getAttribute('id') + `_${iva}`;
      input.setAttribute('name', name);
      iva += 1;
    });
    document.querySelectorAll('.calc-preapproval__loan-part--added .calc__field-mortgage-part-start-date').forEach((input) => {
      let name = input.getAttribute('id') + `_${ivs}`;
      input.setAttribute('name', name);
      ivs += 1;
    });
  };

  // To avoid doing templating in javascript, we actually create 10 loan-part
  // inputs in the PHP template and keep them hidden with css. This function
  // finds the first hidden loan part and makes it visible.
  const addLoanPart = () => {
    const addedClass = 'calc-preapproval__loan-part--added';
    for (let i = 0; i < 10; i += 1) {
      const loanPart = getElem(`calc-preapproval__loan-part--${i}`);
      if (!loanPart.classList.contains(addedClass)) {
        loanPart.classList.add(addedClass);

        // It's possible that we added more than 1 loan part but then deleted one of the
        // earlier ones or deleted and then added again. In either case, the positions of
        // the inputs in the UI can change so we need to change their numbering accordingly.
        renumberLoanParts();
        return;
      }
    }
  };


  const getLoanParts = () => {
    const loanAmounts = [];
    const loanMonths = [];
    document.querySelectorAll('.calc-preapproval__loan-part--added #calc__field-mortgage-part-amount').forEach((loanAmountInput) => {
      loanAmounts.push(parseInt(loanAmountInput.value, 10));
    });
    document.querySelectorAll('.calc-preapproval__loan-part--added #calc__field-mortgage-part-start-date').forEach((startDateInput) => {
      /* globals moment */
      const diffMonths = moment().diff(moment(startDateInput.value, 'DD-MM-YYYY'), 'months');
      const interestDeductionDuration = 360;
      loanMonths.push(interestDeductionDuration - diffMonths);
    });
    return loanAmounts.map((amount, idx) => {
      return { "amount": amount, "monthsRemaining": loanMonths[idx] };
    });
  };

  // const getIsShortStudentLoan = () => {
  //   const elem = getElem('calc__radio-studentloan-short');
  //   return elem && elem.checked;
  // };

  // This function allows users to quickly input a wide variety of dates roughly in the
  // format DD-MM-YYYY but with some shortcuts that are unambiguous. Worst cast it will
  // assume what the use meant and it could be off if the input was ambiguous.
  const fixupDateInput = (inputField) => {
    if (inputField.id === 'calc__field-mortgage-part-start-date') {
      inputField.value = inputField.value.replace(/^([0123][\d]|[456789])[-]?([01][\d]|[23456789])[-]?([\d]{0,4}).*$/, "$1-$2-$3");

      // fix year
      inputField.value = inputField.value.replace(/^([0123][\d]|[456789])[-]?([01][\d]|[23456789])[-]?([01][\d]).*$/, "$1-$2-20$3");

      // fix month
      inputField.value = inputField.value.replace(/^([0123][\d]|[456789])[-]?([\d])[-]?([\d]{4}).*$/, "$1-0$2-$3");

      // fix day
      inputField.value = inputField.value.replace(/^([\d])[-]?([01]?[\d]{2})[-]?([\d]{4}).*$/, "0$1-$2-$3");
    }
  };

  // Shows and hides the monthly income fields if the user selects that option.
  //
  // VG NOTE: This uses some timeouts to change the display between "none" and
  // "block" because leaving these inputs with a "block" display but "hidden"
  // was shifting the layouts in a way that I couldn't fix with CSS. That's why
  // I'm outright removing the display  at the beginning or end of the CSS transitions.
  const setupToggleMonthlyIncome = () => {
    const yearly = getElem('calc__radio-income-yearly');
    const monthly = getElem('calc__radio-income-monthly');

    if (yearly && monthly) {
      yearly.onclick = () => {
        document.querySelectorAll('.calc__monthly-income').forEach((incomeBlock) => {
          incomeBlock.classList.replace('calc__monthly-income--visible', 'calc__monthly-income--hidden');
          setTimeout(() => { incomeBlock.style.display = "none"; }, 400);
        });
      };
      monthly.onclick = () => {
        document.querySelectorAll('.calc__monthly-income').forEach((incomeBlock) => {
          incomeBlock.style.display = "block";
          setTimeout(() => { incomeBlock.classList.replace('calc__monthly-income--hidden', 'calc__monthly-income--visible'); }, 1);
        });
      };
    }
  };

  // Obtains the income and calculates the annual income if the monthly input option was selected.
  const getIncome = (incomeFieldId) => {
    const amount = getIntInput(incomeFieldId);
    const yearlyIncome = getElem('calc__radio-income-yearly');

    if (!yearlyIncome || yearlyIncome.checked) {
      return amount;
    }

    const month13 = getElem(`calc__checkbox-${incomeFieldId}-13th-month`);
    const holidayAllowanceRate = getRate(`${incomeFieldId}-holiday-allowance`);
    const additionalIncome = getIntInput(`${incomeFieldId}-additional`);

    const holidayAllowanceAmount = amount * holidayAllowanceRate * 12;
    const extraMonthAmount = month13.checked ? amount : 0;
    const twelveMonthsAmount = amount * 12;
    const additionalIncomeAmount = additionalIncome * 12;

    return holidayAllowanceAmount + extraMonthAmount + twelveMonthsAmount + additionalIncomeAmount;
  };

  const getIncomeTablesKey = () => {
    const tableKeys = Object.keys(incomeToMaxPaymentPercentTables);
    let incomeTableKey = new Date().getFullYear();

    const tableOptions = document.querySelectorAll(".calc__field-income-table-current-income-table-next input");
    tableOptions.forEach((radioOption) => {
      if (radioOption.checked) {
        incomeTableKey = getElem(`${radioOption.id}-text`).innerText;
      }
    });
    return incomeTableKey;
  };


  const getIncomeTables = () => {
    /* globals incomeToMaxPaymentPercentTables */
    return incomeToMaxPaymentPercentTables[getIncomeTablesKey()];
  };

  // This function plays out the loan at each month to figure out what
  // the monthly princial and interest will be after some number of months.
  const getPrincipalAndInterestAtMonth = (rate, monthlyPayment, loanAmount, month) => {
    let curInterestPayment;
    let curPrincipalPayment;
    for (let i = 0; i <= month; i += 1) {
      const monthlyRate = rate / 12;
      curInterestPayment = loanAmount * monthlyRate;
      curPrincipalPayment = monthlyPayment - curInterestPayment;
      loanAmount -= curPrincipalPayment;
    }
    return [Math.round(curPrincipalPayment), Math.round(curInterestPayment)];
  };

  // Try lots of different rates until we find the best one.
  // Starts from the inputted rate and work up higher.
  // Doesn't make sense to check lower rates, since those usually have
  // better results and the adviser would use them if they were possible.
  const calculateOptimalRate = (calcInputs, startingRate) => {
    let bestRate = startingRate;
    const [initialLoanPartsAmount, initialLoanPartsPayment] =
      calculateLoanPartsPortions(getLoanParts(), startingRate);
    [bestMortage] =
      calcMaxMortgage(calcInputs, startingRate, initialLoanPartsPayment, initialLoanPartsAmount);


    // Make the last digit of rate be 0.0001 (0.01%) because the optimal rate
    // will always end that way based on how the rate tables are defined.
    startingRate = (Math.round(startingRate * 1000) + 0.1) / 1000;

    for (let curRate = startingRate; curRate < 0.07; curRate += 0.001) {
      const [loanPartsTotalAmount, loanPartsMonthlyPayment] =
        calculateLoanPartsPortions(getLoanParts(), curRate);
      const [curMortgage] =
        calcMaxMortgage(calcInputs, curRate, loanPartsMonthlyPayment, loanPartsTotalAmount);
      // console.log(`curMortgage: ${curMortgage}, curRate: ${curRate}, bestMortage: ${bestMortage}, bestRate: ${bestRate}`);
      if (curMortgage > bestMortage) {
        bestMortage = curMortgage;
        bestRate = curRate;
      }
    }
    return [bestRate, bestMortage];
  };

  const recalculate = () => {
    const rate = getRate('rate');
    const rateFloating = getRate('rate-floating');
    // console.log(`rateFloating: ${rateFloating}`);

    const percentLinear = getElem('calc__radio-linear').checked ? 1 : 0;


    let nhg = 0;
    let nhgOptionAvailable = document.getElementById('calc__radio-nhg_yes') != null;

    // transfer tax
    let hasTransferTax = 0;

    if (getElem('calc__radio-newbuilding') && getElem('calc__radio-newbuilding').checked) {
      hasTransferTax = 0;
    }

    if (getElem('calc__radio-transfer_tax_option_0') && getElem('calc__radio-transfer_tax_option_0').checked) {
      hasTransferTax = 0;
    } else if (getElem('calc__radio-transfer_tax_option_1') && getElem('calc__radio-transfer_tax_option_1').checked) {
      hasTransferTax = 1;
    } else if (getElem('calc__radio-transfer_tax_option_2') && getElem('calc__radio-transfer_tax_option_2').checked) {
      hasTransferTax = 2;
    }

    let grossIncomeInput = getIncome('gross-income');
    let partnerIncomeInput = getIncome('partner-income');

    if (getIncome('partner-income') > getIncome('gross-income')) {
      grossIncomeInput = getIncome('partner-income');
      partnerIncomeInput = getIncome('gross-income');
    }

    const inputs = {
      grossIncome: grossIncomeInput,
      partnerIncome: partnerIncomeInput,
      // studentLoans: getIntInput('student-loans'),
      alimony: getIntInput('alimony'),
      debts: getIntInput('debts'),
      months: 360, // making this constant at 30 years
      purchasePrice: getIntInput('purchase-price'),
      mortgage: getIntInput('mortgage'),
      box3amount: getIntInput('non-deductible'),
      annualGroundLease: getIntInput('annual-ground-lease'),
      studentLoanShort: getIntInput('studentloan-short'),
      studentLoanLong: getIntInput('studentloan-long'),
      // isShortStudentLoan: getIsShortStudentLoan(),
      incomeTables: getIncomeTables(),
    };

    const [loanPartsAmount, loanPartsPayment] =
      calculateLoanPartsPortions(getLoanParts(), rate);
    const [floatingLoanPartsAmount, floatingLoanPartsPayment] =
      calculateLoanPartsPortions(getLoanParts(), rateFloating);
    const [maxMortgage, maxMonthlyPayment] =
      calcMaxMortgage(inputs, rate, loanPartsPayment, loanPartsAmount);

// console.log('3 maxMortgage', maxMortgage);
// console.dir('inputs', inputs);
// console.log(inputs.mortgage);
// console.log('rate', rate);
// console.log('loanPartsPayment', loanPartsPayment);
// console.log('loanPartsAmount', loanPartsAmount);
    let nhgLimit = 405001;
    if( getIncomeTablesKey() == 2022 ) {
      nhgLimit = 355001;
    }
    const getNHG = (nhgOptionAvailable && inputs.mortgage < nhgLimit && getElem('calc__radio-nhg_yes').checked) ? getElem('calc__field-nhg_percentage').value * (inputs.mortgage / 100) : 0;

    const requiredOrMaxLoanAmount =
      maxMortgage >= 0 && maxMortgage < inputs.mortgage ? maxMortgage : inputs.mortgage;
    const monthlyPayment =
      calcMonthlyPayment(requiredOrMaxLoanAmount, rate, inputs.months, percentLinear, -1);
    const ownCapitalResults =
      calcOwnCapitalRequired(inputs.purchasePrice, inputs.mortgage, requiredOrMaxLoanAmount, hasTransferTax, getNHG);
    const princialInterestMonth1 =
      getPrincipalAndInterestAtMonth(rate, monthlyPayment, requiredOrMaxLoanAmount, 0);
    // 48 = 4 years * 12 months (starting with the 0th month)
    const princialInterestYear5 =
      getPrincipalAndInterestAtMonth(rate, monthlyPayment, requiredOrMaxLoanAmount, 48);

    const maxPrincialInterestMonth1 =
      getPrincipalAndInterestAtMonth(rate, maxMonthlyPayment, maxMortgage, 0);
    // 48 = 4 years * 12 months (starting with the 0th month)
    const maxPrincialInterestYear5 =
      getPrincipalAndInterestAtMonth(rate, maxMonthlyPayment, maxMortgage, 48);

    // Only set these if we're using the preapproval calculator for
    // advisers (which we assume if the floating rate is defined)
    if (rateFloating > 0) {
      const [optimalRate, optimalMortgage] = calculateOptimalRate(inputs, rate);
      const [maxMortgageFloating] =
        calcMaxMortgage(inputs, rateFloating, floatingLoanPartsPayment, floatingLoanPartsAmount);
// console.log('rateFloating', rateFloating);
// console.log('1 maxMortgage', maxMortgage);
      setOutputWithRate('max-mortgage-amount', maxMortgage, rate);
      setOutputWithRate('max-mortgage-amount-floating', maxMortgageFloating, rateFloating);
      setOutputWithRate('optimal-rate', optimalMortgage, optimalRate);
    } else {
      // Otherwise don't append the rate to the output
      setOutputValue('max-mortgage-amount', maxMortgage);
    }
    setOutputValue('monthly-payment', monthlyPayment);
    setOutputValue('monthly-payment-1st-month-principal', princialInterestMonth1[0]);
    setOutputValue('monthly-payment-1st-month-interest', princialInterestMonth1[1]);
    setOutputValue('monthly-payment-5th-year-principal', princialInterestYear5[0]);
    setOutputValue('monthly-payment-5th-year-interest', princialInterestYear5[1]);

    setOutputValue('max-monthly-payment', maxMonthlyPayment);
    setOutputValue('max-monthly-payment-1st-month-principal', maxPrincialInterestMonth1[0]);
    setOutputValue('max-monthly-payment-1st-month-interest', maxPrincialInterestMonth1[1]);
    setOutputValue('max-monthly-payment-5th-year-principal', maxPrincialInterestYear5[0]);
    setOutputValue('max-monthly-payment-5th-year-interest', maxPrincialInterestYear5[1]);

    setOutputValue('nhg', getNHG);
    setOutputValue('own-capital', ownCapitalResults.total);

    // console.log(ownCapitalResults);
    setOutputValue('own-capital-extra-capital', ownCapitalResults.extraCapital);
    setOutputValue('own-capital-taxes', ownCapitalResults.taxes);
    setOutputValue('own-capital-realtor-fee', ownCapitalResults.realtorFee);
    setOutputValue('own-capital-appraisal-fee', ownCapitalResults.appraisalFee);
    setOutputValue('own-capital-advice-fee', ownCapitalResults.adviceFee);
    setOutputValue('own-capital-notary-fee', ownCapitalResults.notaryFee);
    setOutputValue('own-capital-renovation', ownCapitalResults.renovation);
    setOutputValue('total-income', inputs.grossIncome + inputs.partnerIncome);


    // console.log(`maxMortgage: ${maxMortgage}, loanAmount: ${requiredOrMaxLoanAmount}, monthlyPayment: ${monthlyPayment}`);
  };

  const deleteLoanPart = (deleteEvent) => {
    const loanPart = findAncestor(deleteEvent.target, 'calc-preapproval__loan-part--added');
    loanPart.classList.remove('calc-preapproval__loan-part--added');
    renumberLoanParts();
    recalculate();
  };


  const calcInputChanged = (inputField) => {
    inputField.oninput = () => {
      // Autoformat date inputs
      fixupDateInput(inputField);
      recalculate();
    };
  };


  const addClickHandler = (id, handler) => {
    if (getElem(id)) {
      getElem(id).onclick = handler;
    }
  };

  // Add a change handler for all input fields
  document.querySelectorAll('.calc__input-field').forEach(calcInputChanged);

  // Init click handlers if they exist
  addClickHandler('calc__advanced-option__show', toggleAdvancedDisplay);
  addClickHandler('calc__advanced-option__hide', toggleAdvancedDisplay);
  addClickHandler('calc-preapproval__add-loan-part', addLoanPart);
  document.querySelectorAll('.calc-preapproval__loan-part__delete').forEach((deleteLink) => {
    deleteLink.onclick = deleteLoanPart;
  });
  setupToggleMonthlyIncome();
};
