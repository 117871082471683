/* eslint-disable */
function toggleButtons(el, showHide = 'show') {
  const quizButtons = el.querySelectorAll(".quiz__button");
  quizButtons.forEach(function(button) {
    if (showHide == 'show') {
      button.style.display = 'inline-block';
    } else {
      button.style.display = 'none';
    }
  });

}

function displayQuestion(el, index) {s
  const quizId = el.getAttribute('data-id');
  const quiz = quizData[quizId];
  const question = quiz.questions[index].question;
  const quizButtons = el.querySelectorAll(".quiz__button");

  // Display the statement and true/false options
  el.querySelector(".quiz__question").innerHTML = question;
  quizButtons.forEach(function(button) {
    button.addEventListener("click", function() {
      checkAnswer(el, index, quiz, this.getAttribute('data-answer'));
    });
  });

  el.querySelector(".quiz__button-next").addEventListener("click", function() {
    displayQuestion(el, this.getAttribute('data-next'));
    this.style.display = 'none';
    toggleButtons(el, 'show');
    el.querySelector(".quiz__answer").innerHTML = '';
    el.querySelector(".quiz__explanation").innerHTML = '';
  });
}

function checkAnswer(el, index, quiz, userAnswer) {
  const quizLength = el.getAttribute('data-length');
  const answer = quiz.questions[index].answer;
  const explanation = quiz.questions[index].explanation;

  if (userAnswer === answer) {
    el.querySelector(".quiz__answer").innerHTML = '✔️ ' + quiz.answer_correct;
  } else {
    el.querySelector(".quiz__answer").innerHTML = '❌ ' + quiz.answer_wrong;
  }
  el.querySelector(".quiz__explanation").innerHTML = explanation;
  const nextQuestion = parseInt(el.querySelector(".quiz__button-next").getAttribute('data-next')) + 1;
  toggleButtons(el, 'hide');
  if (nextQuestion < quizLength) {
    el.querySelector(".quiz__button-next").style.display = 'inline-block';
    el.querySelector(".quiz__button-next").setAttribute('data-next', nextQuestion);
  } else {
  }
}

export const enhancer = el => {
  displayQuestion(el, 0);

};
