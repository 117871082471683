// ajax call for extended-navigation
import { getCurrentBreakpoint } from './responsive';

/**
 * get navigation
 */
export const enhancer = el => {
  if (getCurrentBreakpoint() === 'tiny' || getCurrentBreakpoint() === 'small' || getCurrentBreakpoint() === 'medium') {
    const links = el.querySelectorAll(".menu-item-has-children > a");
    for (let i = 0; i < links.length; i += 1) {
      links[i].addEventListener('click', e => {
        if (links[i].parentElement.querySelector('.sub-menu').classList.contains('sub-menu--collapsed')) {
          links[i].parentElement.querySelector('.sub-menu').classList.remove('sub-menu--collapsed');
        } else {
          links[i].parentElement.querySelector('.sub-menu').classList.add('sub-menu--collapsed');
        }
        e.preventDefault();
      });
    }
  }
};
