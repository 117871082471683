// ajax call for extended-navigation
import { getCurrentBreakpoint } from './responsive';

let element;

/**
 * show navigation
 */
const show = () => {
  if (getCurrentBreakpoint() === 'tiny' || getCurrentBreakpoint() === 'small') {
    element.innerHTML = '';
    return;
  }

  const TEMPLATE_ID = element.getAttribute('data-template-id');
  element.innerHTML = document.getElementById(TEMPLATE_ID).innerHTML;
};

/**
 * get navigation
 */
export const enhancer = el => {
  element = el;
  show();
  window.addEventListener('resize', show);
  window.addEventListener('orientationchange', show);
};
