/* eslint-disable */
import { map } from './util';

export const enhancer = el => {
  let html = '';

  /* global viisi */
  const url = `${viisi.ajax_url}?action=post_related&id=${viisi.id}`;
  fetch(url)
    .then(response => response.json())
    .then(data => {
      if (!data.success) return;
      html = '<div class="news-listing background-color--white"><div class="news-listing__inner "><h2 class="news-listing__title">Bekijk ook</h2><div class="news-listing__list">';
      map(data.items, (post) => {
        html += '<div class="news-listing__list-item">';
        html += '<a class="news-listing__list-item-link link--seamless" href="' + post.url + '">';
        if (post.icon) {
          html += '<img class="news-listing__list-item-icon" src="' + post.icon + '" alt="" />';
        }
        html += post.image;
        html += '<div class="news-listing__list-item-content">';
        html += '<p class="news-listing__list-item-subtitle">' + post.subtitle + '</p>';
        html += '<h3 class="news-listing__list-item-title">' + post.title + '</h3>';
        html += '</div></a></div>';
      });
      html += '</div></div>';
      el.innerHTML = html;
    });
};
/* eslint-enable */
