const removeNotice = () => {
  const notice = document.getElementsByClassName('notice')[0];
  notice.remove();
};

/**
 * enhancer
 */
export const enhancer = el => {
  if (sessionStorage.getItem('noticeBar')) {
    removeNotice();
    return;
  }
  const close = document.getElementsByClassName('notice__close')[0];
  document.body.classList.add('has-notice-bar');
  close.addEventListener('click', e => {
    removeNotice();
    sessionStorage.setItem('noticeBar', 1);
  });
};
