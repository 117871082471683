export const enhancer = el => {
  const dots = el.querySelectorAll('.reviews__slider-dot');
  const reviews = el.querySelectorAll('.reviews__review');
  for (let i = 0; i < dots.length; i += 1) {
    if (i === 0) {
      dots[i].classList.add('reviews__slider-dot--active');
    }
    dots[i].addEventListener('click', e => {
      el.querySelector('.reviews__slider-dot--active').classList.remove('reviews__slider-dot--active');
      dots[i].classList.add('reviews__slider-dot--active');
      reviews[i].scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' });
    });
  }
};
