import { map } from './util';

export const enhancer = el => {
  const className = 'gform__input--filled';
  const fields = el.querySelectorAll('input, select, textarea');
  map(fields, (field) => {
    field.addEventListener('change', e => {
      if (field.value === '') {
        field.classList.remove(className);
      } else {
        field.classList.add(className);
      }
    });
  });
};
