import { getLat, getLng } from './geolocation';

export default () => {
  /* global viisi */
  const url = `${viisi.ajax_url}?action=get_closest_offices&lat=${getLat()}&long=${getLng()}`;
  fetch(url)
    .then(response => response.json())
    .then(data => {
      sessionStorage.setItem('offices', JSON.stringify(data));
    })
    .catch(error => console.error(error));
};
