import { map } from './util';

export const enhancer = el => {
  const text = document.getElementsByClassName('refinance__content')[0];
  const image = document.getElementsByClassName('refinance__advisor-image')[0];
  const intro = document.getElementsByClassName('gfield-refinance__intro')[0];
  const form = document.getElementsByClassName('refinance__form')[0];
  const interests = document.getElementsByClassName('validate-interest');
  const amounts = document.getElementsByClassName('validate-amount');

  intro.appendChild(text);
  form.insertBefore(image, form.firstChild);

  map(interests, (fields) => {
    const interestFields = fields.getElementsByTagName('input');
    map(interestFields, (field) => {
      field.addEventListener('keyup', e => {
        field.value = field.value.replace(/[^0-9.,]*/g, '').replace('.', ',');
      });
    });
  });
  map(amounts, (fields) => {
    const amountFields = fields.getElementsByTagName('input');
    map(amountFields, (field) => {
      field.addEventListener('keyup', e => {
        field.value = field.value.replace(/[^0-9.,]*/g, '').replace('.', ',');
      });
    });
  });
};
