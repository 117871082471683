export const enhancer = (el) => {
  const title = el.getElementsByClassName('steps__panel-title');
  for (let i = 0; i < title.length; i += 1) {
    title[i].classList.add('steps__panel--collapse');
    title[i].addEventListener('click', function (e) {
      if (this.classList.contains('steps__panel--collapse')) {
        this.classList.remove('steps__panel--collapse');
        this.classList.add('steps__panel--expanded');
      } else {
        this.classList.remove('steps__panel--expanded');
        this.classList.add('steps__panel--collapse');
      }
    });
  }
};
