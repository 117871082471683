import { getCurrentBreakpoint } from './responsive';

export const enhancer = el => {
  let image = el.getAttribute('data-image');
  const BREAKPOINT = getCurrentBreakpoint();

  if (BREAKPOINT === 'medium') {
    image = el.getAttribute('data-image--medium');
  }

  if (BREAKPOINT === 'large' || BREAKPOINT === 'huge') {
    image = el.getAttribute('data-image--large');
  }

  el.classList.add('hero--featured');
  el.style.backgroundImage = `url(${image})`;
};
