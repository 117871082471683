export const enhancer = el => {
  const offices = JSON.parse(sessionStorage.getItem('offices'));
  const obj = offices[0];

  obj.forEach((prop) => {
    if (el.querySelector(`.${prop}`)) {
      el.querySelector(`.${prop}`).innerHTML = `${obj[prop]}`;
      if (prop === 'phone') {
        el.querySelector(`.${prop}`).setAttribute('href', `tel:${obj[prop]}`);
      }
    }
  });
};
