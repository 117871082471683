/**
 * Responsive breakpoint registry
 */

let docWidth;
let docHeight;

const setDocWidth = () => {
  docWidth = document.documentElement.clientWidth;
};

export const getDocWidth = () => {
  if (!docWidth) {
    setDocWidth();
  }
  return docWidth;
};

const setDocHeight = () => {
  docHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
};

export const getDocHeight = () => {
  if (!docHeight) {
    setDocHeight();
  }
  return docHeight;
};

export const getCurrentBreakpoint = () => {
  return getComputedStyle(document.body, ':before').content.replace(/[^a-zA-Z]+/g, '');
};

export const enhancer = () => {
  window.addEventListener('resize', setDocWidth);
  window.addEventListener('orientationchange', setDocWidth);
};
