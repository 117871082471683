/**
 * set attributes
 */
const setOrigin = (el) => {
  el.setAttribute('data-toggle-origin-href', el.getAttribute('href'));
  el.setAttribute('data-toggle-origin-class', el.getAttribute('class'));
};

/**
 * get attributes
 */
const getAttributes = (el) => {
  return {
    el,
    hash: window.location.hash,
    class: el.getAttribute('class'),
    hashOrigin: el.getAttribute('data-toggle-origin-href'),
    classOrigin: el.getAttribute('data-toggle-origin-class'),
    hashToggle: el.getAttribute('data-toggle-href'),
    classToggle: el.getAttribute('data-toggle-class'),
  };
};

/**
 * get hash attributes
 */
export const getHash = (args) => {
  return args.hashOrigin === args.hash ? args.hashToggle : args.hashOrigin;
};

/**
 * get className
 */
export const getClassNames = (args) => {
  return args.classOrigin === args.class ? args.classToggle : args.classOrigin;
};

/**
 * enhancer
 */
export const enhancer = el => {
  setOrigin(el);
  el.addEventListener('click', e => {
    el.setAttribute('href', getHash(getAttributes(el)));
    el.setAttribute('class', getClassNames(getAttributes(el)));
  });
};
