import { map } from './util';

/**
 * Get url of website
 */
export const getUrl = () => {
  /* globals viisi */
  return viisi.url;
};

/**
 * Check if url is external of not
 * Internal links aren't without domain of checked by domain
 */
const isExternalLink = (url) => {
  if (url.includes('viisi')) {
    return false;
  }
  return url.includes('http');
};

export default () => {
  const links = document.getElementsByTagName('a');
  map(links, (link) => {
    link.addEventListener('click', e => {
      const href = link.getAttribute('href');
      if (href !== null && isExternalLink(href)) {
        e.preventDefault();
        window.open(href);
      }
    });
  });
};
