/* eslint-disable */
export const getGeo = () => {
  if (!localStorage.getItem('geo')) return;
  const geo = JSON.parse(localStorage.getItem('geo'));
  const { lat } = geo.location;
  const { lng } = geo.location;
  return {
    lat, lng,
  };
};

export const getLat = () => {
  if (getGeo()) {
    return getGeo().lat;
  }
};

export const getLng = () => {
  if (getGeo()) {
    return getGeo().lng;
  }
};

export const getGeolocation = () => {
  /* global viisi */
  const url = `${viisi.ajax_url}?action=get_geolocation`;
  fetch(url)
    .then(response => response.json())
    .then(geo => {
      localStorage.setItem('geo', JSON.stringify(geo));
    });
};

export const getIP = () => {
  /* global viisi */
  const url = `${viisi.ajax_url}?action=get_geolocation_ip`;
  fetch(url)
    .then(response => response.json())
    .then(geoip => {
      sessionStorage.setItem('geoip', JSON.stringify(geoip));
      if (geoip.ip !== localStorage.getItem('geoip')) {
        localStorage.setItem('geoip', JSON.stringify(geoip));
        getGeolocation();
      }
    });
};

export default () => {
  // check if session storage exists
  if (sessionStorage && !sessionStorage.getItem('geoip')) {
    getIP();
  } else if (localStorage && !localStorage.getItem('geo')) {
    getGeolocation();
  }
};
/* eslint-enable */
