import { map } from './util';

export const enhancer = el => {
  const links = el.getElementsByClassName('share-list__item-link');
  map(links, (link) => {
    link.addEventListener('click', e => {
      ga('send', 'event',
        'Click',
        'Share',
        'Share Buttons',
        link.text.trim());
    });
  });
};
