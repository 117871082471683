import { map } from './util';

// push wrapper
function pushEvent(label, event, eventCategory, eventLabel) {
  dataLayer.push({
    "label": label,
    "event": event,
    "eventCategory": eventCategory,
    "eventLabel": eventLabel,
  });
/*
  var log = {event: 'dataLayer', label: label, event: event,
  eventCategory: eventCategory, eventLabel: eventLabel}; console.table(log);
*/
}

export default () => {
  const buttons = document.getElementsByClassName('button');
  map(buttons, (button) => {
    button.addEventListener('click', e => {
      const label = 'Click';
      const event = button.href;
      const eventCategory = 'Button';
      const eventLabel = button.text;
      pushEvent(label, event, eventCategory, eventLabel);
    });
  });
};
